<template>
  <div class="cadastro-usuario">
    <v-container fluid>
      <h1 class="titulo">Cadastro de concurso recorrente</h1>

      <div class="box-table">
        <v-skeleton-loader
          :loading="loadingTable"
          :transition="'fade-transition'"
          height="500"
          type="table">
          <v-data-table
            :headers="headers"
            :search="search"
            :items="listaItens"
            width="300px"
            flat
            hide-default-footer
            :loading="loadingPag"
            :items-per-page.sync="itemsPerPage"> 
            
            <template v-slot:top>
              <BrToolBar @search="buscar" :itemsSelect="listaItensSelect" :labelSelects="'Filtrar concurso'"  :configFilter="{ listaSelect: itemSelectKeys, getItens: getItens, jsonData: false }"/>
            </template>

            <template v-slot:item.recurringInterval="{ item }">
              {{ item.startInterval }} minutos
            </template>

            <template v-slot:item.weekDays="{ item }">
              {{ daysWeek(item.weekDays) }}
            </template>

            <template v-slot:item.dtStart="{ item }">
              {{ item.dtStart | datas }}
            </template>
            
            <template v-slot:item.sweepstakeQty="{ item }">
              {{ item.sweepstakeQty }}
            </template>

            <template v-slot:item.action="{ item }">
              <v-btn
                v-if="permiteAcao($route, 'edit')"
                small
                @click="editItem(item)"
                tile
                outlined 
                :color="variables.colorPrimary"
                class="mr-2"
              >
                Editar
              </v-btn>

              <v-btn
                v-if="permiteAcao($route, 'delete')"
                small
                @click="deleteItem(item)"
                tile
                outlined 
                :color="variables.colorError"
              >
                Excluir
              </v-btn>
            </template>

            <template v-slot:footer>
              <v-pagination
                v-model="page"
                :length="numberOfPages"
                circle
                :disabled="disabled"
                :total-visible="7"
                class="box-table-paginacao" 
                v-if="numberOfPages > 1"></v-pagination>
            </template>
          </v-data-table>
        </v-skeleton-loader>
      </div>

      <v-btn class="mx-2 fab-cadastrar-usuario" fab color="primary" @click="cadastrar">
        <v-icon dark>mdi-plus</v-icon>
      </v-btn>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { filter } from 'lodash'
import { convertMinutesToHours } from '@/core/service/utils'

import Events from '@/core/service/events'
// import { errorSnackbar } from '@/core/service/utils'

/**
 * MIXIN
 */
import listagemTables from '@/views/mixins/listagemTables'

const namespaceStore = 'sweepstakesRecurrent'

export default {
  name: 'SweepstalesRecurrent',
  components: {
    BrToolBar: () => import('./components/toolbar')
  },
  mixins: [listagemTables],
  data: () => ({
    headers: [
      { align: 'start', class: 'table-header', text: 'ID', value: 'id' },
      { align: 'start', class: 'table-header', text: 'Código do concurso', value: 'cod' },
      { align: 'start', class: 'table-header', text: 'Intervalo início', value: 'recurringInterval' },
      { align: 'start', class: 'table-header', width: '300px', text: 'Dias da semana', value: 'weekDays' },
      { align: 'start', class: 'table-header', text: 'Data de inicio', value: 'dtStart' },
      { align: 'start', class: 'table-header', text: 'Qtd gerados', value: 'sweepstakeQty' },
      { align: 'end', class: 'table-header', text: '', value: 'action', sortable: false },
    ]
  }),

  mounted () {
    // this.getItensModalities()
  },

  computed: {
    ...mapGetters(namespaceStore, ['totalItens', 'item']),
    listaItens () {
      if (this.selectFilter) {
        return filter(this.$store.getters['sweepstakesRecurrent/listaItens'], (item) => {
          return item.modalityId === this.selectFilter
        })
      }
      return this.$store.getters['sweepstakesRecurrent/listaItens']
    },

    listaItensSelect () {
      return [{
        text: 'Selecione um filtro',
        value: ''
      }, ...this.$store.getters['sweepstakesRecurrent/listaItemSelectMethods'] ]
    },

    itemSelectKeys () {
      return {
        ascertainedMethodId: '',
        cod: '',
        date: '',
        dtDraw: '',
        dtEnd: '',
        dtStart: '',
      }
    }
  },

  methods: {
    ...mapActions(namespaceStore, ['getItens', 'buscarItem', 'deletarItem', 'setItem', 'clearItens', 'getMethos']),
    ...mapActions('modalities', {
      getItensModalities: 'getItens'
    }),

    editItem (item) {
      Events.$emit('cadastro::openModalCadastro', item)
      this.setItem(item)
      // this.dialog = true
    },

    convertMinutesToHoursTable(date) {
      return convertMinutesToHours(date)
    },

    daysWeek (daysWeek) {
      const arrayDaysWeek = daysWeek.split('')
      let array = []
      if (!arrayDaysWeek.length) return null

      arrayDaysWeek.forEach((item, index) => {
        if (parseInt(item)) array.push(this.dayWeek(index + 1))
      })

      return array.join(', ')
    },

    dayWeek (index) {
      switch (index) {
        case 1:
          return 'Domingo'
        case 2:
          return 'Segunda-feira'
        case 3:
          return 'Terça-feira'
        case 4:
          return 'Quarta-feira'
        case 5:
          return 'Quinta-feira'
        case 6:
          return 'Sexta-feira'
        case 7:
          return 'Sábado'
      }
    }
  }
}
</script>


<style lang="scss" src="./style.scss"></style>